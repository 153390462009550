import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VForm,{on:{"submit":function($event){$event.preventDefault();return _vm.submitHandler.apply(null, arguments)}}},[_c(VCardText,[_c('login-inputs',{staticClass:"d-flex flex-column flex-gap-16",attrs:{"errors":_vm.errors},on:{"input":_vm.onItemChange},model:{value:(_vm.item),callback:function ($$v) {_vm.item=$$v},expression:"item"}})],1),_c(VCardActions,{staticClass:"d-flex justify-space-between ma-2 align-center"},[_c('div',[_c('router-link',{attrs:{"to":{ name: 'forgot-password' }}},[_vm._v(_vm._s(_vm.$t('Forgot password'))+"?")])],1),_c('div',[_c(VBtn,{attrs:{"depressed":"","disabled":!_vm.isValid,"loading":_vm.loading,"color":"primary","type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t('Login'))+" ")])],1)])],1),_c(VContainer,{staticClass:"version-login version_fix"},[_c(VRow,[_c(VCol,{staticClass:"text-center",attrs:{"cols":"6"}},[_c('div',{staticClass:"centered-label"},[_c('span',{staticClass:"d-block text-left"},[_vm._v("V3.3.0")])])]),_c(VCol,{staticClass:"text-center",attrs:{"cols":"6"}},[_c('div',{staticClass:"centered-label"},[_c('span',{staticClass:"d-block text-right"},[_vm._v("EPC1.1.0")])])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }